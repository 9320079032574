<template>
  <modal
    :name="modalName"
    adaptive
    :width="500"
    @before-open="beforeOpen"
    height="auto"
    class="modal--multiple-contact"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ title }}
      </div>
      <section class="modal--container">
        <div class="title--text hide--in-mobile">
          {{ title }}
        </div>
        <div class="wrapper--main">
          <div
            v-for="(contact, index) in contacts"
            :key="`contact-${index}`"
            id="contact-us-btn"
            @click="handleContact(contact)"
            style="cursor: pointer"
            class="mb-4"
          >
            <div class="btn btn--whatsapp action--button">
              <img class="mr-2" src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
              <span>{{ `PIC ${index + 1}` }}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {},
  data: () => ({
    modalName: 'modal--multiple-contact',
    loading: false,
    title: '',
    contacts: null,
    agentUuid: null,
    listingUuid: null,
    projectUuid: null,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.global.loggedIn,
      user: (state) => state.global.user,
    }),
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.$store.commit('v2/contact/RESTORE_DEFAULT_VALUE');

      this.title = this.$t('multipleContact.modal.title');
      this.contacts = event.params.contacts;
      this.agentUuid = event.params.agentUuid;
      this.listingUuid = event.params.listingUuid;
      this.projectUuid = event.params.projectUuid;
    },
    handleContact(contact) {
      try {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          this.windowReference = window.open();
        }
        this.handleContactAction(isSafari, contact);
      } catch (e) {
        console.log('ERROR HANDLE CONTACT: ', e);
      }
    },
    async handleContactAction(isSafari, contact) {
      let url = '';

      // eslint-disable-next-line no-unused-vars
      const { data } = await this.$http.post('/api/lead/create', {
        agent_uuid: this.agentUuid,
        listing_uuid: this.listingUuid,
        project_uuid: this.projectUuid,
        source: 'website',
      });
      let textMessage = '';
      textMessage += `Halo, saya ingin mendapat informasi lebih lanjut mengenai properti ini `;
      textMessage += this.$store.state.global.baseUrl + this.$route.path;
      url = `https://api.whatsapp.com/send?phone=${contact.phone}&text=${textMessage}`;

      if (isSafari) {
        if (this.windowReference) {
          this.windowReference.location = url;
        }
      } else {
        // Not working in safari (window.open in async function)
        window.open(url, '_blank');
      }
    },
  },
};
</script>
